<template>
  <div>
    <x-table
      title="协议列表"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDeploy="rowDeploy"
      @rowUnDeploy="rowUnDeploy"
      @rowReDeploy="rowReDeploy"
      @rowDelete="rowDelete">
      <template slot="form-configuration-location" slot-scope="scope">
        <el-upload
          v-if="scope.type !== 'view' && jarFlag"
          class="upload-demo mb-1"
          :action="action"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
          :on-success="(response) => uploadSuccess(response, scope.row)">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">点击上传</b-button>
        </el-upload>
        <b-form-input
          :disabled="scope.type === 'view'"
          v-model="scope.row.configuration.location"
          placeholder="请输入文件地址"
        />
      </template>
      <template slot="form-configuration-script" slot-scope="scope">
        <div class="codeEditBox">
          <editor
            v-model="scope.row.configuration.script"
            @init="editorInit"
            lang="javascript"
            :options="editorOptions"
            :theme="theme"
          ></editor>
        </div>
      </template>
    </x-table>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, deploy, unDeploy, remove, add,
} from '@/api/device-access/agreement-list'
import {
  BFormInput, BButton,
} from 'bootstrap-vue'
import { getToken } from '@/@core/auth/token'
import Editor from 'vue2-ace-editor'
import usAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    XTable,
    BFormInput,
    BButton,
    Editor,
  },
  data() {
    return {
      jarFlag: false,
      code: '',
      editorOptions: {
        // 设置代码编辑器的样式
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: 14, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
      },
      // 系统主题
      themeConfig: usAppConfig().skin.value,
      // 脚本编辑器主题
      theme: 'pastel_on_dark',
      // 上传请求地址
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      content: [{
        prop: 1,
        title: '第一步',
        content: '啊哈大口袋里',
      }, {
        prop: 2,
        title: '第二部',
        content: '打卡来看待',
      }],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'deploy', name: '发布', icon: 'GitBranchIcon' },
          { action: 'unDeploy', name: '取消发布', icon: 'GitPullRequestIcon' },
          { action: 'reDeploy', name: '重新发布', icon: 'GitCommitIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state === 1) {
            return action === 'view' || action === 'edit' || action === 'reDeploy' || action === 'unDeploy'
          }
          return action === 'view' || action === 'edit' || action === 'delete' || action === 'deploy'
        },
        columns: [{
          label: '协议ID',
          prop: 'id',
          editDisable: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入协议ID',
          },
        }, {
          label: '名称',
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入协议名称',
          },
        }, {
          label: '类型',
          labelShow: true,
          prop: 'type',
          searchShow: true,
          rowSelect: true,
          type: 'select',
          dictData: [
            {
              label: 'local',
              value: 'local',
            },
            {
              label: 'jar',
              value: 'jar',
            },
            {
              label: 'script',
              value: 'script',
            },
          ],
          selectVariant: prop => {
            if (prop === 'local') {
              return 'light-primary'
            }
            if (prop === 'jar') {
              return 'light-warning'
            }
            return 'light-success'
          },
          onDictSelected: (option, column) => {
            if (option === 'local' || option === 'jar') {
              column[3].addShow = true
              column[3].editShow = true
              column[3].children[0].addShow = true
              column[3].children[0].viewShow = true
              column[3].children[0].editShow = true
              column[3].children[1].addShow = true
              column[3].children[1].editShow = true
              column[3].children[1].viewShow = true
              column[3].children[2].addShow = false
              column[3].children[2].editShow = false
              column[3].children[2].viewShow = false
              column[3].children[3].addShow = false
              column[3].children[3].editShow = false
              column[3].children[3].viewShow = false
              column[3].children[4].addShow = false
              column[3].children[4].editShow = false
              column[3].children[4].viewShow = false
              this.jarFlag = false
              if (option === 'jar') {
                this.jarFlag = true
              }
            }
            if (option === 'script') {
              column[3].addShow = true
              column[3].editShow = true
              column[3].children[2].addShow = true
              column[3].children[2].viewShow = true
              column[3].children[2].editShow = true
              column[3].children[3].addShow = true
              column[3].children[3].viewShow = true
              column[3].children[3].editShow = true
              column[3].children[4].addShow = true
              column[3].children[4].editShow = true
              column[3].children[4].viewShow = true
              column[3].children[0].addShow = false
              column[3].children[0].editShow = false
              column[3].children[0].viewShow = false
              column[3].children[1].addShow = false
              column[3].children[1].editShow = false
              column[3].children[1].viewShow = false
            }
          },
          rules: {
            rule: 'required',
            message: '请选择类型',
          },
        }, {
          label: '配置',
          labelShow: false,
          prop: 'configuration',
          rowShow: false,
          viewShow: false,
          addShow: false,
          editShow: false,
          searchShow: false,
          children: [
            {
              label: '类名',
              labelShow: true,
              rowShow: false,
              viewShow: false,
              addShow: false,
              editShow: false,
              prop: 'provider',
              rows: 5,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入类名',
              },
            },
            {
              label: '文件地址',
              labelShow: true,
              rowShow: false,
              addShow: false,
              editShow: false,
              prop: 'location',
              rows: 5,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入文件地址',
              },
            },
            {
              label: '协议标识',
              labelShow: true,
              rowShow: false,
              viewShow: false,
              addShow: false,
              editShow: false,
              prop: 'protocol',
              rows: 5,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入协议标识',
              },
            },
            {
              label: '连接协议',
              labelShow: true,
              prop: 'transport',
              searchShow: true,
              rowShow: false,
              addShow: false,
              editShow: false,
              type: 'checkbox',
              dictData: [
                {
                  name: 'MQTT',
                  id: 'MQTT',
                },
                {
                  name: 'UDP',
                  id: 'UDP',
                },
                {
                  name: 'CoAP',
                  id: 'CoAP',
                },
                {
                  name: 'TCP',
                  id: 'TCP',
                },
                {
                  name: 'HTTP',
                  id: 'HTTP',
                },
                {
                  name: 'HTTPS',
                  id: 'HTTPS',
                },
              ],
              props: { label: 'name', value: 'id' },
              rules: {
                rule: 'required',
                message: '请选择连接协议',
              },
            },
            {
              label: '脚本',
              labelShow: true,
              rowShow: false,
              addShow: false,
              editShow: false,
              prop: 'script',
              rows: 5,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入脚本',
              },
            },
          ],
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          searchShow: true,
          viewShow: false,
          addShow: false,
          editShow: false,
          rowSelect: true,
          type: 'select',
          dictData: [
            {
              id: 0,
              name: '未发布',
            },
            {
              id: undefined,
              name: '未发布',
            },
            {
              id: 1,
              name: '已发布',
            },
          ],
          props: { label: 'name', value: 'id' },
          selectVariant: prop => {
            if (prop === 1) {
              return 'success'
            }
            return 'danger'
          },
        }, {
          label: '描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rowCls: 'x-text-cut',
          rows: 5,
          searchShow: false,
        },
        ],
      },
    }
  },
  watch: {
    themeConfig(val) {
      // 切换脚本编辑器的主题
      if (val === 'dark') {
        require('brace/theme/pastel_on_dark')
        this.theme = 'pastel_on_dark'
      } else {
        require('brace/theme/tomorrow')
        this.theme = 'tomorrow'
      }
    },
  },
  created() {
    // 切换脚本编辑器的主题
    if (this.themeConfig === 'dark') {
      require('brace/theme/tomorrow_night_eighties')
      this.theme = 'tomorrow_night_eighties'
    } else {
      require('brace/theme/tomorrow')
      this.theme = 'tomorrow'
    }
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    // 上传jar包
    uploadSuccess(response, row) {
      this.$xtoast.success('上传成功')
      row.configuration.location = response.result
    },
    // 新增
    addHandle(formData, done) {
      formData.lang = 'js'
      add(formData).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      add(data).then(() => {
        done()
      })
    },
    // 删除协议
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    // 发布、重新发布
    rowDeploy(data, done) {
      deploy(data.id).then(() => {
        done()
      })
    },
    // 重新发布
    rowReDeploy(data, done) {
      deploy(data.id).then(() => {
        done()
      })
    },
    // 取消发布
    rowUnDeploy(data, done) {
      unDeploy(data.id).then(() => {
        done()
      })
    },
    editorInit() {
      // require('brace/theme/solarized_dark')
      require('brace/ext/language_tools') // language extension prerequsite...
      require('brace/mode/yaml')
      require('brace/mode/json')
      require('brace/mode/less')
      require('brace/snippets/json')
      require('brace/mode/lua')
      require('brace/snippets/lua')
      require('brace/mode/javascript')
      require('brace/snippets/javascript')
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .codeEditBox{
    width:100%;
    height:200px;
    border:1px solid #dcdee2;
  }
</style>
